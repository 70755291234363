/* colors */
$dark: #222222;
$darkGradient: linear-gradient(to right, #222222 0, #141414 100%);
$darkBlue: #0f2b46;
$blue: #004b93;
$light: #fff;
$orange: #f7a721;
$success: #28a745;
$error: #dc3545;

/* shadows */
$nav-box-shadow: 0px 2px 16px rgb(0 0 0 /48%);

/* responsive design */
$responsive: 500px;

// nav height
$nav-height: 70px;
